<template>
    <div class="m-classify">
        <v-header></v-header>
        <div class="m-detail-view">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
        </div>
        <v-footer></v-footer>
    </div>
</template>

<script>
    import vFooter from "@/components/mobile/vFooter.vue";
    import vHeader from "@/components/mobile/vHeader.vue";

    export default {
        name: "classify",
        components: {
            vHeader,
            vFooter
        },
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to) {
                    const path = to.path;
                    if (path == '/mobile/shgk' || (path.indexOf('/list') > 0 && path.indexOf('/mobile') == 0)
                        || path == '/mobile/information' || path == '/mobile/member'
                        || path == '/mobile/link') {
                        this.wxShareInfo();
                    }
                }
            }
        },
        methods: {
            //微信分享
            wxShareInfo() {
                const {wxSdkUtil} = require("@/util/wechat/wxSdkUtil")
                let obj = {};
                obj.title = '中国侨商联合会';
                obj.content = '';
                obj.img = 'http://' + window.location.host + '/upload/default/newstitle.jpg';
                wxSdkUtil.setShareInfo(obj.img, obj.title, obj.content, null)
            }
        }
    }
</script>

<style scoped>
    .m-detail-view {
        padding-top: 3rem;
        width: 100%;
    }

    .m-classify {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        height: 100vh;
    }
</style>
