<template>
    <div class="v-header">
        <div class="m-header">
            <img class="m-logo" src="@/assets/images/mobile/index_logo.png">
            <em class="m-nav-icon" @click="showRightNav"></em>
        </div>
        <div class="catalogue-mask" @touchmove.prevent @click="hideRightNav" v-show="isMask"></div>
        <div class="m-right-nav" :style="{transform:'translateX('+num+'%)'}">
            <i class="m-close" @click="hideRightNav"></i>
            <ul class="m-catalogue">
                <li v-for="(item,index) in topNavList2" @click.stop="hideRightNav" :key="index">
                    <a v-if="item.title=='会员专区'" :href="item.link">{{item.title}}</a>
                    <router-link v-else
                            :to="{ path: item.link,query:{typeId:item.typeId}}"
                            :key='index'>
                    {{item.title}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>


    export default {
        name: "vHeader",
        data() {
            return {
                winH: "auto",
                num: 100,
                isHeaderTab: 0,
                isMask: false,
                topNavList2: [
                    {link: '/mobile', title: '首页'}, //1
                    {link: '/mobile/information?typeId=qwzx', title: '侨务资讯', typeId: 'qwzx'},
                    {link: '/mobile/information?typeId=jrsz', title: '今日时政', typeId: 'jrsz'},
                    {link: '/mobile/information?typeId=bhxw', title: '本会新闻', typeId: 'bhxw'},
                    {link: '/mobile/information?typeId=hydt', title: '会员动态', typeId: 'hydt'},
                    {link: '/mobile/information?typeId=qqfc', title: '侨企风采', typeId: 'qqfc'},
                    {link: '/mobile/information?typeId=dfsh', title: '地方商会', typeId: 'dfsh'},
                    {link: '/mobile/member?typeId=hyfc', title: '会员专区',typeId:'hyfc'},
                    {link: '/mobile/information?typeId=flfg', title: '法律法规', typeId: 'flfg'}, //1
                    {link: '/mobile/hyqy/list?typeId=hyqy', title: '会员企业',typeId:'hyqy'},
                    {link: '/mobile/information?typeId=ztbg', title: '专题报道', typeId: 'ztbd'},
                    {link: '/mobile/gdfh/list', title: '各地商会', typeId: ''}, //1
                    {link: '/mobile/information?typeId=zszx', title: '招商资讯', typeId: 'zszx'},
                    {link: '/mobile/shkw/list', title: '商会刊物', typeId: ''},
                    {link: '/mobile/shgk', title: '商会概况', typeId: 'shgk'},
                    {link: '/mobile/information?typeId=xxgk', title: '信息公开', typeId: 'xxgk'},
                    {link: '/mobile/information?typeId=tzgg', title: '通知公告', typeId: 'tzgg'},
                    {link: '/mobile/link', title: '友情链接', typeId: ''}, //1
                ]
            }
        },
        methods: {
            showRightNav() {
                this.num = 0;
                this.isMask = true;
            },
            hideRightNav(i) {
                this.num = 100;
                this.isMask = false;
                this.isHeaderTab = i;
            }
        }
    }
</script>

<style scoped>
    .catalogue-mask {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        padding: .6rem 1rem;
        background: rgba(0, 0, 0, .5);
    }

    .v-header {
        height: 13vw;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 2222;
        padding: .6rem 1rem;
        background: #f5f5f5;
    }

    .m-header {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .m-logo {
        width: 39vw;
        height: 7vw;
    }

    .m-nav-icon {
        position: absolute;
        right: 4vw;
        top: 50%;
        margin-top: -0.466666666667rem;
        display: block;
        width: 4vw;
        height: 4vw;
        background: url("~@/assets/images/mobile/m_nav.png") no-repeat center;
        background-size: 1rem;
    }

    .m-right-nav {
        width: 12.7333333333rem;
        height: 100vh;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        overflow-y: scroll;
        bottom: 0;
        z-index: 2001;
        transform: translateX(100%);
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
    }

    .m-close {
        width: 2rem;
        height: 3rem;
        display: block;
        background: url("~@/assets/images/mobile/m_close.png") no-repeat right 1rem top 1rem;
        background-size: 0.733333333333rem;
        float: right;
    }

    .m-right-nav ul {
        height: 100vh;
        padding-top: 2.6rem;
        padding-bottom: 3rem;
    }

    .m-right-nav ul li {
        width: 100%;
    }

    .m-catalogue li > a {
        font-size: 1.06666666667rem;
        color: #000;
        display: block;
        width: 100%;
        height: 100%;
        padding: .5rem 2rem;
        white-space: nowrap;
    }

    .m-catalogue li > a.router-link-exact-active {
        color: #005bac;;
        background-color: white;
    }

    .m-catalogue li > a.active.router-link-exact-active.addColor {
        color: #005bac;;
        background-color: white;
    }

    .m-catalogue li > a.active.addColor {
        color: #000;
        background-color: white;
    }

    .active {
        background-color: white !important;
    }

    .second-level {
        padding: 0 2rem;
    }

    .second-level a {
        font-size: 1rem;
        color: #000;
        display: block;
        padding: .5rem 0 .5rem 2rem;
        white-space: nowrap;
    }

    .second-level a.router-link-exact-active {
        color: #005bac;;
    }
</style>
