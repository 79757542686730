<template>
  <div class="m-footer">
    <div class="footer-content">
      <img class="logo-icon" src="../../assets/images/mobile/logo.png" >
      <div class="content-text">
        <p>北京市朝阳区建外街道金和东路20号院世界华商中心6层</p>
        <p>邮编:100020</p>
        <p style="margin-bottom: 6vw;">联系电话:010-65220149</p>
        <p>版权所有:中国侨商联合会</p>
        <p><a href="https://beian.miit.gov.cn" style="color: #fff" target="_blank">京ICP备2021037705号-1</a></p>
        <div style="margin:0 auto; padding:0px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048298" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/img/gaba.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;">京公网安备 11010502048298号</p></a>
        </div>
      </div>

    </div>
	<div class="footer-linkBox">
	<p v-for="item,index in linkData" :key="index" @click="golink(item.linkUrl)">{{item.linkName}}</p>
	</div>
  </div>
</template>

<script>

  export default {
    name: "vFooter",
    data() {
      return {
          linkData:[]
      }
    },
    created() {
          this.linkGetData();
    },
    mounted() {

    },
    methods: {
      gobook () {
        window.location.href = "http://www.52hrtt.com/webservicepage_getInformationPage.do?id=G1537497662397&areaId=1&languageId=1&behaviorSource=4"
      },
	  linkGetData() {
	  	let _this = this
	  	this.$http.yqljList({
	  		page: _this.page
	  	}).then(res => {
	  		if (res.code == 200) {
	  			_this.linkData = res.data.records
	  			// this.play()
	  			// console.log(this.linkData)
	  		}
	  	})
	  },
	  golink(url){
	  	window.location.href = url
	  }
    }
  }
</script>

<style scoped>
  .m-footer {
    background-image: linear-gradient(180deg,
    		#239e77 0%,
    		#0868a2 100%),
    	linear-gradient(
    		#ccddf8,
    		#ccddf8);
    padding: 6vw;
  }
  .footer-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-icon{
    width: 31vw;
    height: 28vw;
  }
  .content-text{
    font-size: 3vw;
    line-height: 5vw;
    color: #ffffff;
	margin-left: 4vw;
  }
  .footer-linkBox{
	width: 90vw;
  	box-sizing: border-box;
  	margin: 2rem auto 0;
  	display: flex;
  	flex-wrap: wrap;
  }
  .footer-linkBox p{
  	cursor: pointer;
  	padding:0 0.733333rem 0 ;
  	margin-bottom: 1.333333rem;
  	border-right:  0.066666rem solid rgba(255,255,255,0.2);
  	font-size: 0.666666rem;
  	color: #e5e5e5;
  }
</style>
